<template>
  <v-container
    class="dynamic-form inner-container inner-container--content"
  >
    <Form
      :id="anchorName"
      :data="data.form"
      :form_id="data.form_id"
      :title="data.name"
    />
  </v-container>
</template>

<script>
import dynamicBlock from "@/components/dynamic/dynamicBlock.js";
export default {
  mixins: [dynamicBlock],
  components: {
    Form: () => import("@/components/dynamic/form/form.vue"),
  },
};
</script>