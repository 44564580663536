export default {
  props: {
    data: Object
  },
  computed: {
    // 區塊標題
    name() {
      if(!this.data) return ''
      return this.data.name
    },
    type() {
      if(!this.data) return ''
      return this.data.type
    },
    useAnchor() {
      if(!this.data) return false
      return !!this.data.use_anchor
    },
    anchorName() {
      if(!this.data) return ''
      return this.$helper.handleAnchorName(this.data.anchor_name)
    },
    // 有些元件格式不一樣
    blockData() {
      //TODO
      if(!this.data) return null
      return this.data.data
    },
    // 有些元件格式不一樣
    items() {
      //TODO
      if(!this.blockData) return []
      return this.blockData.items
    },
    hasItems() {
      return !_isEmpty(this.items)
    },
  },
}